



























































































































































































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import {
  GetBookList,
  PostSuoding,
  GetShitiManageList,
  ShitikuSuoding,
} from "@/request/check";
import MainHeader from "@/components/main-header/main-header.vue";
import ShitiHengji from "../../components/hengji/shiti-hengji.vue";
import YuanwenHengji from "../../components/hengji/yuanwen-hengji.vue";
@Component({
  components: {
    MainHeader,
    ShitiHengji,
    YuanwenHengji,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  private data: any[] = [];
  private editData: any = {};
  private ifShowHengji: any = false;
  private hengjiId: any = "";
  private ifShowYuanwenHengji: any = false;
  private hengjiData: any = [];
  private status: any = ["可校验", "已锁定"];
  private fenleiData: any = [];
  private cancelHengji() {
    this.ifShowHengji = false;
  }
  private cancelYuanwenHengji() {
    this.ifShowYuanwenHengji = false;
  }
  private changeType(val: any) {
    this.filters.type = val;
    this.filters.status = "";
    this.filters.search = "";
    this.filters.current_page = 1;
    this.filters.sort_field = "更新时间";
    this.filters.sort_value = 0;
    this.getList();
  }

  private openHengji(item: any) {
    if (!this.getIsShowQuanxian("校验管理查看校验痕迹")) {
      return;
    }
    if (this.filters.type == "古籍") {
      if (!this.getIsShowQuanxian("校验管理查看校验痕迹")) {
        return;
      }
      this.hengjiId = item["book_id"];
      this.ifShowYuanwenHengji = true;
    } else {
      this.hengjiId = item["概念"];
      this.ifShowHengji = true;
    }
  }
  private goRead(item: any) {
    if (item["库名"] == "古籍") {
      this.$router.push({
        path: "/main/check/yuanwenjiaoyan/read",
        query: {
          id: item.id,
        },
      });
    } else {
      this.$store.commit("updateIfCompose", false);
      this.$router.push({
        path: "/main/check/bentijiaoyan/compose",
        query: {
          id: item.history_id,
          kind: item.kind,
          type: "hengji",
        },
      });
    }
  }
  /**
   * @description 获取列表
   */
  getList() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    if (this.filters.type === "古籍") {
      const params: any = {
        params: {
          search: this.filters.search,
          status: this.filters.status,
          order_field: this.filters.sort_field,
          order_value: this.filters.sort_value,
          current_page: this.filters.current_page,
          page_num: this.filters.page_count,
        },
      };
      localStorage.setItem("jiaoyanguanliFilter", JSON.stringify(this.filters));
      GetBookList(this, params, loading)
        .then((data: any) => {
          loading.close();
          this.data = data.data;
          this.filters.total_count = data.total_count;
          this.filters.total_page = data.total_page;
          if (this.data.length === 0) {
            this.ifShowEmpty = true;
          } else {
            this.ifShowEmpty = false;
          }
        })
        .catch(() => {
          loading.close();
        });
    } else {
      localStorage.setItem("jiaoyanguanliFilter", JSON.stringify(this.filters));
      const params: any = {
        params: {
          current_page: this.filters.current_page,
          page_num: this.filters.page_count,
        },
      };
      if (this.filters.sort_field !== "更新时间") {
        params.params.order_field = this.filters.sort_field;
        params.params.order_value = this.filters.sort_value;
      }
      GetShitiManageList(this, params, loading)
        .then((data: any) => {
          loading.close();
          this.data = data.data;
          this.filters.total_count = data.total_count;
          this.filters.total_page = data.total_page;
          if (this.data.length === 0) {
            this.ifShowEmpty = true;
          } else {
            this.ifShowEmpty = false;
          }
        })
        .catch(() => {
          loading.close();
        });
    }
  }
  private suoding(item: any, val: any) {
    if (!this.getIsShowQuanxian("校验管理锁定")) {
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    if (this.filters.type === "古籍") {
      if (!this.getIsShowQuanxian("校验管理锁定")) {
        return;
      }
      const params: any = {
        book_id: item.book_id,
      };
      PostSuoding(this, params)
        .then((res: any) => {
          loading.close();
          this.$message.success(val + "成功！");
          this.getList();
        })
        .catch(() => {
          loading.close();
        });
    } else {
      const params: any = {
        id: item.id,
      };
      ShitikuSuoding(this, params)
        .then((res: any) => {
          loading.close();
          this.$message.success(val + "成功！");
          this.getList();
        })
        .catch(() => {
          loading.close();
        });
    }
  }
  /**
   * @description 初始化
   */
  mounted() {
    this.filters.type = "古籍";
    this.filters.status = "";
    this.filters.sort_field = "更新时间";
    const d = localStorage.getItem("jiaoyanguanliFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.getList();
  }
}
